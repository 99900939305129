import React from "react";
import styled from "styled-components";
import UpgradeIcon from "@pcloud/web-utilities/dist/resources/images/svg/menu/upgrade.svg";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import { PCLOUD_LINKS } from "@pcloud/web-utilities/dist/config/constants";
import { useSelector } from "react-redux";

const UpgradeItem = () => {
    const isFamily = useSelector(({ pCloudUser }) => pCloudUser.userinfo.family);

    const handleClick = () => {
        window.location.href = URLSITE + (
          isFamily ? PCLOUD_LINKS.FAMILY_PRICING : PCLOUD_LINKS.CLOUD_STORAGE_PRICING
        );
    };

    return (
        <Wrapper id={"diamond-container"} onClick={handleClick}>
            <UpgradeIcon />
        </Wrapper>
    );
};

export default UpgradeItem;

const Wrapper = styled.div`
    cursor: pointer;
`;
