// @flow

import React, { PureComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { __ } from "../../lib/translate";
import { formatSizeInGb } from "../../lib/utils";

import Button from "../../components/ButtonDefault";
import { Container, Header, Message, Footer } from "./SharedFamilyComponents";
import StorageManagment from "./StorageManagment";
import { MIN_USER_QUOTA, QUOTA_STEP } from "@pcloud/web-utilities/dist/config/constants";
import type { Member, Invite } from "./types";

type Props = {
  currentItem: Member | Invite,
  onUpdateMemberButton: () => void,
  onSuccess: () => void,
  onClose: () => void
};


const ManageQuotaModal = ({
  currentItem = {},
  onUpdateMemberButton = () => { },
  onSuccess = () => { },
  onClose = () => { }
}: Props) => {
  const {
    userinfo: { quota: ownerQuota, usedquota: ownerUsedQuota } = {}
  } = useSelector((state) => state.pCloudUser);

  const [updatedQuota, setUpdatedQuota] = useState(0);
  const [shouldChangeQuota, setShouldChangeQuota] = useState(true);
  const [canProceed, setCanProceed] = useState(false);

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      onUpdate();
    }
  }

  useEffect(() => {
    document.addEventListener("keyup", onEnter);
    canChangeMemberQuota();
    return () => {
      document.removeEventListener("keyup", onEnter);
    }
  }, [])

  useEffect(() => {
    // Calculate previous and current free quotas
    const prevOwnerFreeQuota = ownerQuota - ownerUsedQuota;
    const prevMemberFreeQuota = currentItem.quota - currentItem.usedquota;

    const ownerFreeQuota = ownerQuota - ownerUsedQuota;
    const memberFreeQuota = currentItem.quota - currentItem.usedquota;

    // Check if quotas have changed
    if (ownerFreeQuota !== prevOwnerFreeQuota || prevMemberFreeQuota !== memberFreeQuota) {
      canChangeMemberQuota();
    }
  }, [ownerQuota, ownerUsedQuota, currentItem, canChangeMemberQuota]);

  const canChangeMemberQuota = () => {
    const { quota, usedquota } = currentItem;
    const ownerFreeQuota = ownerQuota - ownerUsedQuota;
    const ownerMinQuota = (ownerQuota % QUOTA_STEP) + MIN_USER_QUOTA;
    const hasAvailableOwnerQuota = hasAvailableFreeQuota(
      ownerFreeQuota,
      ownerUsedQuota,
      ownerMinQuota
    );
    const memberUsedQuota = usedquota;
    const memberFreeQuota = quota - memberUsedQuota;
    const hasAvailableMemberQuota = hasAvailableFreeQuota(
      memberFreeQuota,
      memberUsedQuota,
      MIN_USER_QUOTA
    );

    if (!hasAvailableOwnerQuota && !hasAvailableMemberQuota) {
      setShouldChangeQuota(false);
    }
  }

  const hasAvailableFreeQuota = (freeQuota, usedQuota, minQuota) => {
    if (usedQuota < minQuota) {
      return freeQuota - minQuota > QUOTA_STEP;
    } else {
      return freeQuota > QUOTA_STEP;
    }
  }

  const getUpdatedQuota = (updatedQuota) => {
    const { quota } = currentItem;

    setUpdatedQuota(updatedQuota);
    setCanProceed(updatedQuota !== quota);
  }

  const getMaxValue = () => {
    const freeOwnerQuota = ownerQuota - ownerUsedQuota;
    const { quota } = currentItem;

    if (ownerUsedQuota < MIN_USER_QUOTA) {
      return quota + freeOwnerQuota - MIN_USER_QUOTA;
    } else {
      return quota + freeOwnerQuota;
    }
  }

  const onUpdate = () => {
    const { userid } = currentItem;

    if (!canProceed) {
      return;
    }

    onUpdateMemberButton(userid, updatedQuota);
    onSuccess();
  }

  const { email, quota, usedquota } = currentItem;
  const freeOwnerQuota = ownerQuota - ownerUsedQuota;
  const startValue = quota;
  const maxValue = getMaxValue();
  const minStartValue = Math.ceil(usedquota / QUOTA_STEP) * QUOTA_STEP;

  console.log("memberQuota", formatSizeInGb(quota));
  console.log("member used quota", formatSizeInGb(usedquota));
  console.log("member free quota", formatSizeInGb(quota - usedquota));
  console.log("owner free quota", formatSizeInGb(freeOwnerQuota));
  console.log("ownerQuota", formatSizeInGb(ownerQuota));
  console.log("ownerUsedQuota", formatSizeInGb(ownerUsedQuota));
  console.log("startValue", formatSizeInGb(startValue));
  console.log("maxValue", formatSizeInGb(maxValue));
  console.log("maxValue", formatSizeInGb(minStartValue));

  return (
    <Container>
      <StyledHeader>{__("family_manage_quota_modal_header", "Manage Family Member")}</StyledHeader>
      <Message>
        <MemberWrapper>
          <MemberHeader>{__("User", "User")}</MemberHeader>
          <Email>{email}</Email>
        </MemberWrapper>
        {shouldChangeQuota ? (
          <StorageManagment
            isModal
            startValue={startValue}
            minStartValue={minStartValue}
            maxValue={maxValue > startValue ? maxValue : startValue}
            getQuota={getUpdatedQuota}
            valueBoxColor="#f4f4f4"
          />
        ) : (
          __("family_accountfull_tooltip_error")
        )}
      </Message>
      <StyledFooter>
        <Button
          color="lightgray4"
          style={{
            marginRight: "5px"
          }}
          onClick={onClose}
        >
          {__("Close")}
        </Button>
        {shouldChangeQuota ? (
          <Button
            color="cyan"
            style={{
              marginLeft: "5px"
            }}
            onClick={onUpdate}
            disabled={!canProceed}
          >
            {__("family_invite_form_update_button", "Update")}
          </Button>
        ) : null}
      </StyledFooter>
    </Container>
  );
}

export default ManageQuotaModal;

const MemberWrapper = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: var(--spacing-xl);
`;

const MemberHeader = styled.div`
  font-size: var(--font-size-14);
  font-weight: bold;
  margin-bottom: var(--spacing-sm);
  text-align: left;
`;

const Email = styled.div`
  font-size: var(--font-size-16);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 400;
`;

const StyledHeader = styled(Header)`
  margin-bottom: var(--spacing-xl);
`

const StyledFooter = styled(Footer)`
  margin: 0;
`