import React, { useEffect } from "react";

const CustomCopy = () => {

  useEffect(() => {
    const handleCopy = (event) => {
      if (
        event &&
        event.srcElement &&
        event.srcElement.getAttribute('data-copy-value')
      ) {
          event.preventDefault();
          event.clipboardData.setData("text/plain", event.srcElement.getAttribute('data-copy-value'));
      }
    };

    document.addEventListener("copy", handleCopy);

    return () => {
      document.removeEventListener("copy", handleCopy);
    };
  }, []);

  return null;
};

export default CustomCopy;