import React, { useEffect, useState } from "react";
import { apiMethod } from "../../../api";
import BackupAccountUI from "./BackupAccountUI";

const DropBoxLink = () => {
  const [account, setAccount] = useState(null);
  const [unlinkModal, setUnlinkModal] = useState(null);
  const [unlinkLoading, setUnlinkLoading] = useState(false);

  useEffect(() => {
    getToken().then(getIdentity);
  }, [])

  const getToken = () => {
    return new Promise((resolve, reject) => {
      apiMethod("social_assign", {
        type: "dbxn"
      }, response => {
        if (response.access_token) {
          Dropbox.token = response.access_token;
          resolve();
        }
      }, {
        errorCallback: (error) => { console.error(error) }
      })
    })

  }

  const getIdentity = () => {
    Dropbox.apiCallGet('2/users/get_current_account', {}, apiRet => {
      setAccount(apiRet.name.display_name);
    }, {
      onMissingToken: function () {
        apiMethod('social_assign', { type:'dbxn', dismiss: 'true' }, () => {});
      }
    });
  }

  const onLink = () => {
    Dropbox.getTokenCallback((token, expire) => {
      HFN.message(__('Token saved.'));
      getIdentity();
    });
  };

  const onUnlink = () => {
    apiMethod('social_assign', { type: 'dbxn', dismiss: 'true' }, ret => {
      Dropbox.apiCallGet('2/auth/token/revoke', {}, () => {
        HFN.message(__('Account unlinked.'));
        setAccount(null);
      });
    })
  }

  return (
    <BackupAccountUI
      type="dbxn"
      app={__("Dropbox Account")}
      account={account}
      linked={!!account}
      onLink={onLink}
      onUnlink={onUnlink}
    />
  )
}

export default DropBoxLink;