// @flow

import React, { useEffect, useRef, useState } from "react";
import apiMethod from "../../api/apiMethod";
import errors from "../../lib/errors";
import { abortAll } from "../../lib/XhrComponentUtils";
import { __ } from "../../lib/translate";
import FlowManager from "../TwoFactorAuth/FlowManager";
import type { Member, Invite } from "./types";
import {
  ResendInvitationModal,
  CancelInvitationModal,
  ManageQuotaModal,
  RemoveMemberModal,
  CopyLinkModal
} from ".";
import { useSelector, useDispatch } from "react-redux";
import { CANCEL_INVITATION, COPY_LINK, MANAGE_MEMBER, REMOVE_MEMBER, RESEND_INVITATION } from "../../config/constants";
import { setFamilyModalData } from "../../lib/state/reducers/contentSlice";

const modalFlows = {
  [REMOVE_MEMBER]: [RemoveMemberModal],
  [CANCEL_INVITATION]: [CancelInvitationModal],
  [MANAGE_MEMBER]: [ManageQuotaModal],
  [COPY_LINK]: [CopyLinkModal]
};

type Props = {
  disabled: boolean,
  removeMember: number => void,
  manageMember: (number, number) => void,
  cancelInvite: number => void,
};

const FlowManagerWrapper = ({
  disabled,
  manageMember,
  removeMember,
  cancelInvite,
}: Props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.pCloudUser.token);
  const familyModalData = useSelector((({ content }) => content.familyModalData));
  const [currentFlow, setCurrentFlow] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentEmail, setCurrentEmail] = useState("");
  const xhrList = useRef([]);

  useEffect(() => {
    return () => {
      abortAll(xhrList.current);
    }
  }, []);

  useEffect(() => {
    if (familyModalData) {
      if (familyModalData.type === RESEND_INVITATION) {
        onPendingButtonClick(familyModalData.data);
      } else {
        onOpenModal(familyModalData.type, familyModalData.data);
      }
      dispatch(setFamilyModalData(undefined));
    }
  }, [familyModalData]);

  const onOpenModal = (currentModal: string, currentItem: Member | Invite) => {
    const currentFlow = modalFlows[currentModal];

    if (disabled) {
      return;
    }
    setCurrentFlow(currentFlow);
    setCurrentItem(currentItem);
  }

  const onCloseModal = () => {
    setCurrentFlow([]);
    setCurrentItem(null);
  }

  const copyToClipboard = () => {
    const inviteLinkInput = document.getElementById("inviteLink");
    inviteLinkInput && inviteLinkInput.select();
    console.log("inviteLinkInput", inviteLinkInput);
    document.execCommand("copy");
    HFN.message(__("Copied to clipboard."));
  }

  const onRemoveMemberButton = (id: number) => {

    xhrList.current.push(
      apiMethod(
        "fm_removemember",
        {
          auth: token,
          userid: id
        },
        res => {
          removeMember(id);
        },
        {
          errorCallback: ({ result, error }) => {
            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          }
        }
      )
    );
  }

  const onUpdateMemberButton = (id: number, updatedQuota: number) => {
    xhrList.current.push(
      apiMethod(
        "fm_changequota",
        {
          auth: token,
          userid: id,
          quota: updatedQuota
        },
        res => {
          manageMember(id, updatedQuota);
          HFN.message(__("family_manage_quota_updated"));
        },
        {
          errorCallback: ({ result, error }) => {
            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          }
        }
      )
    );
  }

  const onCancelInviteButton = (id: number) => {
    xhrList.current.push(
      apiMethod(
        "fm_cancelinvitation",
        {
          auth: token,
          invitationid: id
        },
        res => {
          cancelInvite(id);
        },
        {
          errorCallback: ({ result, error }) => {
            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          }
        }
      )
    );
  }

  const onPendingButtonClick = (invite: Invite) => {
    const { invitationid } = invite;

    xhrList.current.push(
      apiMethod(
        "fm_resendinvitation",
        {
          auth: token,
          invitationid
        },
        res => {
          HFN.message("family_invitation_sent_again_message");
        },
        {
          errorCallback: ({ result, error }) => {
            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          }
        }
      )
    );
  }

  return (
    <FlowManager
      auth={token}
      key="flow"
      currentItem={currentItem}
      flow={currentFlow}
      shouldCloseOnOverlayClick={true}
      onClose={onCloseModal}
      onRemoveMemberButton={onRemoveMemberButton}
      onUpdateMemberButton={onUpdateMemberButton}
      onCancelInviteButton={onCancelInviteButton}
    />
  );
}

export default FlowManagerWrapper;