import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import apiMethod from "../api/apiMethod";
import { TDiv, TSpan } from "../components/intl";
import { __ } from "../lib/translate";
import SubmitButton from "../components/SubmitButton";
import InputReadOnly from "../components/InputReadOnly";
import InputPassword from "../components/InputPassword";
import * as Style from "./SharedInvitationComponents";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";
import { boldConverter, brTagConverter } from "../lib/componentifyConverters";
import WarnIcon from "../../root/img/svg/warning_invitation.svg";
import Componentify from "react-componentify";

const ChangeEmailConfirmation = ({ code, onChangeMail }) => {
  const [newmail, setNewMail] = useState("");
  const [oldmail, setOldMail] = useState("");
  const [userid, setUserid] = useState(0);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(() => {
    const request = apiMethod(
      "changemailinfo",
      { code },
      (ret) => {
        setNewMail(ret.newmail);
        setOldMail(ret.oldmail);
        setUserid(ret.userid);
        setLoading(false);
      },
      {
        errorCallback: (ret) => {
          if (ret.result === 2012) ret.error = __("Wrong code");
          else if (ret.result === 2074) ret.error = __("change_mail_code_expires", "This link has expired.");
          setError(ret.error);
          setLoading(false);
        },
      }
    );
    return request;
  }, [code]);

  useEffect(() => {
    const request = loadData();
    return () => request && request.abort();
  }, [loadData]);

  const changeEmail = useCallback(
    (e) => {
      e.preventDefault();
      if (password.length < 5) {
        setError(__("provide_password", "Provide password."));
        return;
      }

      setLoading(true);

      apiMethod(
        "changemail",
        { code, password },
        () => {
          setDone(true);
          setLoading(false);
        },
        {
          errorCallback: (ret) => {
            if (ret.result === 2272) ret.error = __("Incorrect password");
            setError(ret.error);
            setPassword("");
            setLoading(false);
          },
        }
      );
    },
    [code, password]
  );

  const renderForm = () => (
    <Style.Form method="post" onSubmit={changeEmail}>
      <div>
        <Style.FormLabel>{__("current_email", "Current email")}</Style.FormLabel>
        <InputReadOnly value={oldmail} />
      </div>
      <div>
        <Style.FormLabel>{__("new_email", "New email")}</Style.FormLabel>
        <InputReadOnly value={newmail}/>
      </div>
      <div>
        <Style.FormLabel>{__("enter_password_confirm", "Enter password to confirm")}</Style.FormLabel>
        <InputPassword
          name="password"
          placeholder={__("your_password", "Your password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          revealPassword
        />
      </div>
      <SubmitButton minWidth={"200px"} loading={loading} active={!loading}>
        {__("change_email_btn", "Change Email")}
      </SubmitButton>
    </Style.Form>
  );

  const renderInitializing = () => (
    <Style.LoadingWrapper>
      <div />
    </Style.LoadingWrapper>
  );

  const renderDone = () => (
    <>
      <Style.FormLabel>{__("email_changed_successfully")}</Style.FormLabel>
      {onChangeMail && (
        <SubmitButton minWidth={"200px"} onClick={() => onChangeMail(newmail, password)}>
          {__("Continue")}
        </SubmitButton>
      )}
    </>
  );

  const renderAlreadyUsed = () => (
    <Style.FormLabel>
      {__("current_email_already_changed", "Your current email has already been changed.")}
    </Style.FormLabel>
  );

  const renderError = (errorText) => (
    <>
      <Style.WarningMessage>
        <WarnIcon />
        <Style.ComponentifyWrapper>
          <Componentify text={errorText} converters={[boldConverter, brTagConverter]} />
        </Style.ComponentifyWrapper>
      </Style.WarningMessage>
      {renderForm()}
    </>
  );

  let content;
  if (loading) content = renderInitializing();
  else if (error) content = renderError(error);
  else if (newmail === oldmail) content = renderAlreadyUsed();
  else if (done) content = renderDone();
  else content = renderForm();

  return (
    <Style.Container>
      <Style.HeaderLogoContainer>
        <PcloudLogo />
      </Style.HeaderLogoContainer>
      <Style.Title>{__("confirm_new_email")}</Style.Title>
      {content}
    </Style.Container>
  );
};

ChangeEmailConfirmation.propTypes = {
  code: PropTypes.string.isRequired,
  onChangeMail: PropTypes.func,
};

export default ChangeEmailConfirmation;
