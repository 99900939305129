//@flow

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { hiDPI } from "polished";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import {
  SettingsWrapper,
  SettingsHeader,
  SettingsTitle,
  SettingsDescription,
  SettingOptionsContainer,
  InputWrapper,
  ToggleLabel,
  Error,
  FadeAnimationContainer
} from "./SharedStyledComponents";
import TopBar from "./TopBar";
import InputCheckbox from "../../components/InputCheckbox";
import Button from "../../components/ButtonDefault";
import { changePublinkApiCall } from "./utils";
import { __ } from "../../lib/translate";
import { roundHours } from "../../lib/utils";
import { setExpires } from "../../lib/state/reducers/sharedLinksSlice";

type Props = {
  allSettingsBack: any,
  bodyHeight: string,
  parentRef: any
};

const defaultTopOffest = 50;

const ExpirationDateSetting = ({ allSettingsBack, bodyHeight, parentRef, currentSettingId }: Props) => {
  const timeNow = new Date();
  const yesterday = Datetime.moment().subtract(1, "day");
  const linkData = useSelector(({ sharedLinks }) => sharedLinks.linkData);
  const isPremiumUser = useSelector(({ pCloudUser }) => pCloudUser.userinfo.premium);
  const isBusinessUser = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const { linkId, expirationDate } = linkData;

  const [toggleState, setToggleState] = useState(!!expirationDate);
  const [loading, setLoading] = useState(false);
  const [changesInited, setChangesInited] = useState(false);
  const [dateValue, setDateValue] = useState((expirationDate && new Date(expirationDate)) || roundHours(timeNow));
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const timezoneOffset = (new Date().getTimezoneOffset() / 60) * -1;
  let timezoneOffsetText = "UTC";

  const buttonTexts = {
    save: __("Save"),
    change: __("Change")
  };
  
  const buttonText = !!expirationDate && toggleState ? buttonTexts.change : buttonTexts.save;


  if (timezoneOffset) {
    timezoneOffsetText += timezoneOffset > 0 ? "+" + timezoneOffset : timezoneOffset;
  }

  const validateDate = current => {
    return current.isAfter(yesterday);
  };

  const handleToggleChange = () => {
    if (loading) {
      return;
    }

    const newToggleState = !toggleState;

    setToggleState(newToggleState);
    setErrorMessage("");

    if (!!expirationDate != newToggleState) {
      setChangesInited(true);
    } else {
      setChangesInited(false);
    }
    
    setDateValue(expirationDate ? new Date(expirationDate) : roundHours(timeNow));
  };

  const handleDateChange = value => {
    setDateValue(value);
    setChangesInited(true);
  };

  const handleSubmit = () => {
    if (!changesInited) {
      return;
    }

    errorMessage && setErrorMessage("");
    setLoading(true);
    changePublink();
  };

  const changePublink = () => {
    const params = {
      linkid: linkId,
      iconformat: "id"
    };

    if (dateValue && toggleState) {
      params.expire = new Date(dateValue).toISOString();
    } else if (!toggleState) {
      params.deleteexpire = true;
    }

    changePublinkApiCall(
      params,
      () => {
        setLoading(false);
        dispatch(setExpires(toggleState ? dateValue : null));
        setChangesInited(false);
        HFN.message(__("shared_links_settings_updated"), "success");
        if (typeof gtag === "function") {
          gtag("event", "shared_link_settings_click", {
            action: "expdate setting change",
            category: "setting",
            type: "premium",
            user_premium: (isPremiumUser || isBusinessUser) ? 1 : 0,
            location: "settings modal",
            value: toggleState ? "on" : "off"
          })
        }
      },
      ({ error }) => {
        setLoading(false);
        setErrorMessage(__(error));
      }
    );
  };

  const renderHeader = () => {
    return (
      <SettingsHeader>
        <SettingsTitle>{__("link_settings_exp_date")}</SettingsTitle>
        <SettingsDescription>{__("link_settings_exp_date_description")}</SettingsDescription>
      </SettingsHeader>
    );
  };

  const renderBody = () => {
    return (
      <SettingOptionsContainer disabledFeature={!(isPremiumUser || isBusinessUser)}>
        <form>
          <InputWrapper>
            <InputCheckbox
              checked={toggleState}
              onChange={handleToggleChange}
              name="expdate-toggle"
              color="cyan"
              size="small"
            />
            <ToggleLabel>{__("link_settings_exp_date")}</ToggleLabel>
          </InputWrapper>
          <DateInputsWrapper>
            <DatePickerWrapper disabled={!toggleState}>
              <Datetime
                value={dateValue}
                minDate={timeNow}
                onChange={handleDateChange}
                className="date-picker"
                viewMode="days"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                inputProps={{ disabled: !toggleState }}
                isValidDate={validateDate}
                closeOnSelect={true}
              />
              <CalendarIcon />
            </DatePickerWrapper>
            <TimePickerWrapper disabled={!toggleState}>
              <Datetime
                value={dateValue}
                onChange={handleDateChange}
                viewMode="time"
                timeFormat="HH:mm"
                dateFormat={false}
                className="time-picker"
                inputProps={{ disabled: !toggleState }}
                closeOnSelect={true}
              />
              <TimeZoneText>{timezoneOffsetText}</TimeZoneText>
            </TimePickerWrapper>
          </DateInputsWrapper>
          <FadeAnimationContainer>
            <SwitchTransition>
              <CSSTransition
                key={errorMessage}
                addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                classNames="fade"
              >
                {errorMessage ? <Error>{errorMessage}</Error> : <Error />}
              </CSSTransition>
            </SwitchTransition>
          </FadeAnimationContainer>
          <ButtonWrapper>
            <Button
              loading={loading}
              disabled={!changesInited}
              borderRadius="3"
              color="cyan"
              onClick={handleSubmit}
            >
              <FadeAnimationContainer>
                <SwitchTransition>
                  <CSSTransition
                    key={buttonText}
                    addEndListener={(node, done) =>
                      node.addEventListener("transitionend", done, false)
                    }
                    classNames="fade"
                  >
                    <span>{buttonText}</span>
                  </CSSTransition>
                </SwitchTransition>
              </FadeAnimationContainer>
            </Button>
          </ButtonWrapper>
        </form>
      </SettingOptionsContainer>
    );
  };

  return (
    <SettingsWrapper
      bodyHeight={bodyHeight}
      ref={parentRef}
      topOffest={isPremiumUser || isBusinessUser ? defaultTopOffest : 90}
    >
      <div>
        <TopBar settingId={currentSettingId} allSettingsBack={allSettingsBack} showAlert={!(isPremiumUser || isBusinessUser)} />
        {renderHeader()}
        {renderBody()}
      </div>
    </SettingsWrapper>
  );
};

export default ExpirationDateSetting;

const DateInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  td.rdtDay {
    font-size: 14px;
    border-radius: 6px;
    transition: background 200ms ease-in-out;

    &.rdtActive {
      background-color: #17bed0 !important;
    }
  }

  .date-picker,
  .time-picker {
    border-radius: 3.1px;
    box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.03);
    border: solid 1px #c1c1c1;
    background-color: #ffffff;
    padding: 7px 12px;
    box-sizing: border-box;
    height: 36px;
    width: 160px;
    outline: none;

    @media (max-width: 600px) {
      width: 100%;
    }

    .rdtPicker {
      left: 0px;
      top: 35px;
      border-radius: 3.1px;
    }
  }

  .time-picker {
    .rdtPicker {
      width: 120px;

      .rdtCounter .rdtBtn {
        font-size: 13px;
        border-radius: 6px;
        transition: background 200ms ease-in-out;
      }
    }
  }
`;

const DatePickerWrapper = styled.div`
  position: relative;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  transition: opacity 250ms ease-in-out;

  input {
    border: none;
    width: 100%;
    outline: none;
    background: transparent;
  }
`;

const TimePickerWrapper = styled(DatePickerWrapper)``;

const CalendarIcon = styled.div`
  content: "";
  display: inline-block;
  position: absolute;
  right: 12px;
  width: 14px;
  height: 14px;
  top: 11px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  background-image: url(${require("../../../root/img/b/callendar-inside.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/b/callendar-inside.png")});
  }
`;

const TimeZoneText = styled.div`
  position: absolute;
  right: 12px;
  top: 11px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 1.23;
  text-align: left;
  color: #999999;
`;

const ButtonWrapper = styled.div`
  a[disabled] {
    background-color: #dddddd;
    color: #a2a2a2;
    opacity: 1;
  }
`;
