import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { __ } from "../../../../../lib/translate";
import { useMenu } from "../../../context/MenuContextProvider";
import {
    getContentOptions,
    getHashPage,
    transormOptionsToList
} from "../../../../../lib";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import { MOBILE_WIDTH_BREAKPOINT } from "../../../../../config/constants";
import CryptoLockButton from "../../../../CryptoLockButton";
import { motion } from "framer-motion";
import { deleteBreadcrumb } from "../../../../../lib/state/reducers/contentSlice";
import hashManager from "../../../../../lib/hashManager";
import Componentify from "react-componentify";
import { boldConverter } from "../../../../../lib/componentifyConverters";

const predefinedPageTitles = {
    filerequests: { key: "Upload Links", default: "Upload Links" },
    cryptolocked: { key: "Crypto Folder", default: "Crypto Folder" },
    b_account: { key: "My Account", default: "My Account" },
    b_teams: { key: "Teams", default: "Teams" },
    b_billing: { key: "Billing", default: "Billing" },
    b_users: { key: "Users", default: "Users" },
    b_logs: { key: "Activity Logs", default: "Activity Logs" },
    "tab-songs": { key: "Audio", default: "Audio" },
    "tab-artists": { key: "Audio", default: "Audio" },
    // "tab-artist": { key: "Audio", default: "Audio" },
    "tab-albums": { key: "Audio", default: "Audio" },
    // "tab-album": { key: "Audio", default: "Audio" },
    "tab-playlists": { key: "Audio", default: "Audio" },
    // "tab-playlist": { key: "Audio", default: "Audio" },
    snimport: { key: "Backups", default: "Backups" },
    snimport2: { key: "Backups", default: "Backups" },
    backups: { key: "Backups", default: "Backups" },
    publinks: { key: "title_shared_links", default: "Shared links" },
    trash: { key: "Trash", default: "Trash" },
    time: { key: "rewind", default: "Rewind" },
    bookmarks: { key: "left_menu_pinned_links", default: "Bookmarks" },
    invitefriends: {
        key: "step_action_invite_friends",
        default: "Invite friends"
    },
    cryptoset: {
        key: "Setting up Crypto Folder",
        default: "Setting up Crypto Folder"
    },
    cryptoshares: { key: "Crypto Shares", default: "Crypto Shares" },
    cryptosharedwithme: { key: "Crypto Shares", default: "Crypto Shares" },
    settings: { key: "Settings", default: "Settings" },
    speedtest: { key: "speed_test_setting", default: "Speed Test" },
    cryptobusiness: { key: "Share files at full security", default: "Share files at full security" },
    cryptobuy: { key: "pCloud Encryption", default: "pCloud Encryption" },
    shares: { key: "label_shares", default: "Shared" },
    "publinks-tab": { key: "label_shares", default: "Shared" },
    "myshares-tab": { key: "label_shares", default: "Shared" },
    "requests-tab": { key: "label_shares", default: "Shared" },
    brandlinks: {
        key: "branding_settings_headline",
        default: "Branding Settings"
    },
    linkstats: {
        key: "Download link traffic statistics",
        default: "Shared link traffic statistics"
    },
    family: { key: "family_left_menu_headline", default: "Family Settings" }
};

const Item = ({
    folder,
    breadcrumbLength,
    index,
    showSkipDots = false,
    isBackupPage = false,
    prevFolder = null
}) => {
    const { bodyWidth } = useWindowDimensions();
    const isMobile = bodyWidth < MOBILE_WIDTH_BREAKPOINT;
    const classList = [];
    const [baseParams, setBaseParams] = useState({ page: getHashPage() });
    let folderName = "";
    const dispatch = useDispatch();

    const { showMenu } = useMenu(null);
    const optionsRef = useRef();
    const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
    const cryptoV2IsActive = useSelector(
        ({ pCloudUser }) => pCloudUser.userinfo.cryptov2isactive
    );
    const isCollaborationEnabled = useSelector(
        ({ pCloudUser }) => pCloudUser.collaboration || false
    );

    const availableOptions = useMemo(
        () => {
            const options = getContentOptions({
                content: [folder],
                isBusiness: isBusiness,
                cryptoV2IsActive: cryptoV2IsActive,
                isCollaborationEnabled: isCollaborationEnabled,
                maxMainOptionsCnt: 0,
                isMobileDevice: HFN.config.isMobile()
            });

            if (!isBusiness) {
                delete options.secondary.fileSection;
            } else {
                // Keep "Add comment" and "View comments"
                const fileSection = [];
                if (options.secondary.fileSection?.indexOf("comment") !== -1) {
                    fileSection.push("comment");
                }

                if (options.secondary.fileSection?.indexOf("showComments") !== -1) {
                    fileSection.push("showComments");
                }

                options.secondary.fileSection = fileSection;
            }
            return options;
        },
        [folder, isBusiness, cryptoV2IsActive, isCollaborationEnabled]
    );

    if (!isBusiness) {
        delete availableOptions.secondary.fileSection;
    }

    const items = useMemo(
        () => transormOptionsToList(availableOptions.secondary, [folder]),
        [availableOptions.secondary, folder]
    );

    useEffect(() => {
        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    const handleHashChange = () => {
        const hashPage = getHashPage();

        if (hashPage !== baseParams.page) {
            dispatch(deleteBreadcrumb());
        }
        setBaseParams({ ...baseParams, page: hashPage });
    };

    const onOptionsClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        showMenu(items, optionsRef);
    };

    const onFolderClick = (folderId, folderEncrypted) => {
        const stateParams = { ...baseParams };

        if (folderId > 0) stateParams.folder = folderId;

        if (folderId == 0 && folderEncrypted === true) {
            stateParams.crypto = 1;
        }

        if (hashManager.getState("backupdesktop")) {
            stateParams.backupdesktop = 1;
        }

        if (hashManager.getState("backupmobile")) {
            stateParams.backupmobile = 1;
        }

        if (hashManager.getState("tpl")) {
            stateParams.tpl = hashManager.getState("tpl");
        }

        if (hashManager.getState("time")) {
            stateParams.time = hashManager.getState("time");
        }

        hashManager.pushState(stateParams, 2);
    };

    folder.backButton = null;
    folder.skip = null;

    if (showSkipDots) {
        folder.skip = true;
        classList.push("skip");
        folderName = "...";
    }

    if (
        (baseParams.page !== "filemanager" ||
            hashManager.getState("crypto") ||
            folder.encrypted ||
            breadcrumbLength > 1) &&
        index === 0
    ) {
        folder.backButton = true;
    }

    if (folder.name === "/") {
        if (baseParams.page === "time") {
            folderName = __("My pCloud", "My pCloud");

            if (hashManager.getState("time")) {
                folderName +=
                    " at " +
                    HFN.toBeautyDate(hashManager.getState("time")) +
                    " " +
                    HFN.toParseTime(hashManager.getState("time"))
                        .split(":")
                        .slice(0, 2)
                        .join(":");
            }
        } else if (baseParams.page === "trash") {
            folderName = __("Trash", "Trash");
        } else if (baseParams.page === "b_logs") {
            folderName = __("Activity Logs", "Activity Logs");
        } else {
            folderName = __("My pCloud ", "My pCloud");
        }
    } else if (
        folder.name == "Crypto Folder" ||
        (folder.virtualfolder == true && folder.encrypted == true)
    ) {
        folderName = "Crypto Folder";
        folder.encrypted = true;
    } else if (
        folder.name === "Backups"
    ) {
        folderName = __(folder.name);
    } else if (
        predefinedPageTitles.hasOwnProperty(baseParams.page) &&
        baseParams.page !== "trash" &&
        index === 0
    ) {
        folderName =
            baseParams.page === "time" && breadcrumbLength > 1
                ? HFN.metaName(folder)
                : __(
                      predefinedPageTitles[baseParams.page].key,
                      predefinedPageTitles[baseParams.page].default
                  );
        folder.backButton = breadcrumbLength === 1;
    } else if (!folder.skip) {
        folderName = HFN.metaName(folder);
    }

    if (
        (folderName === "" || typeof folderName === "undefined") &&
        folder.hasOwnProperty("folderid")
    ) {
        folder.backButton = true;
    }

    if (index === 0) {
        classList.push("home");
    }
    if (index === breadcrumbLength - 1 || breadcrumbLength === 1) {
        classList.push("current");
        classList.push("ellipsis");
    }

    const variants = {
        enter: {
            width: "auto",
            opacity: 1
        },
        exit: { width: 0, opacity: 0 }
    };

    const canRedirectBack = HFN.referrer.canRedirectBack() || isBackupPage;
    const showCryptoLock =
        (folder.encrypted ||
            baseParams.page === "cryptolocked" ||
            baseParams.page === "cryptoshares" ||
            baseParams.page === "cryptosharedwithme") &&
        index === 0 &&
        !isMobile;
    const isCryptoUnclocked =
        !!folder.encrypted ||
        ((baseParams.page === "cryptoshares" || baseParams.page === "cryptosharedwithme") && !pCrypt.locked);

    return (
        <>
            {!!folder.backButton &&
                (canRedirectBack || (breadcrumbLength > 1 && prevFolder)) && (
                    <FolderName
                        className="back-button"
                        onClick={() => {
                            if (typeof gtag === "function") {
                                gtag("event", "navigation_click", {
                                    action: "breadcrumb back button"
                                });
                            }

                            if (
                                folder.hasOwnProperty("customRedirectToState") &&
                                !prevFolder
                            ) {
                                hashManager.pushState(
                                    folder.customRedirectToState,
                                    2
                                );
                                return;
                            }

                            if (
                                breadcrumbLength > 1 &&
                                prevFolder &&
                                (!isBackupPage || breadcrumbLength > 2)
                            ) {
                                onFolderClick(
                                    prevFolder.folderid,
                                    prevFolder.encrypted
                                );
                            } else {
                                HFN.referrer.redirectBack();
                            }
                        }}
                    >
                        <i className="mediumIcon fa-regular fa-chevron-left" />
                    </FolderName>
                )}

            {showCryptoLock && (
                <CryptoLockButton
                    value={isCryptoUnclocked}
                    disableChange={!isCryptoUnclocked}
                    onChange={() => {
                        if (isCryptoUnclocked) {
                            setTimeout(() => {
                                pCloudCrypto.lock();
                                HFN.cache.expireMatch("listfolder");
                                HFN.data.afterCryptoUnlock = hashManager.getState();
                                hashManager.pushState({ page: "cryptolocked" }, 2);
                            }, 500);
                        }
                    }}
                />
            )}
            <FolderName
                variants={breadcrumbLength > 1 ? variants : null}
                initial="exit"
                animate="enter"
                exit="exit"
                transition={{ duration: 0.2 }}
                className={classList.join(" ")}
                onClick={() => {
                    if (folder.hasOwnProperty("customRedirectToState")) {
                        hashManager.pushState(folder.customRedirectToState, 2);
                        return;
                    }

                    index !== breadcrumbLength - 1 && !folder.skip
                        ? onFolderClick(folder.folderid, folder.encrypted)
                        : null;
                }}
            >
                {folderName && <Componentify text={folderName} converters={[boldConverter]} />}
            </FolderName>
            {index < breadcrumbLength - 1 ? (
                <DividerWrapper
                    variants={breadcrumbLength > 1 ? variants : null}
                    initial="exit"
                    animate="enter"
                    exit="exit"
                    transition={{ duration: 0.2 }}
                >
                    <Divider />
                </DividerWrapper>
            ) :
              (index !== 0 || baseParams.page === "trash") &&
              baseParams.page !== "revisions" &&
              items.length ? (
                <OptionsWrapper
                    $isSingle={breadcrumbLength === 1}
                    variants={breadcrumbLength > 1 ? variants : null}
                    initial="exit"
                    animate="enter"
                    exit="exit"
                    transition={{ duration: 0.2 }}
                    ref={optionsRef}
                    onClick={(e) => onOptionsClick(e, items)}
                >
                    <i className="baseIcon fa-regular fa-ellipsis-vertical" />
                </OptionsWrapper>
            ) : null}
        </>
    );
};

export default Item;

const DividerWrapper = styled(motion.div)`
    width: 12px;
`;

const OptionsWrapper = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: ${({ $isSingle }) => ($isSingle ? "-10px" : "0")};
    width: 24px !important;
    height: 24px !important;

    @media (max-width: 1023px) {
        width: 40px !important;
        height: 40px !important;
    }
`;

const Divider = styled.div`
    display: inline-block;
    width: 6px;
    height: 6px;
    border-right: 1px solid var(--text-primary);
    border-bottom: 1px solid var(--text-primary);
    position: relative;
    top: -1px;
    transform: rotate(-45deg);
    transform-origin: center;
`;

const FolderName = styled(motion.div)`
    float: left;
    font-size: 20px;
    line-height: 32px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    white-space: nowrap;
    color: var(--text-primary);

    &.ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    @media (max-width: 1023px) {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        &:not(.back-button) {
            padding-right: 40px;
        }
    }

    &:not(.current) {
        cursor: pointer;
        color: var(--text-secondary);
        font-weight: 400;
    }

    &.back-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        min-width: 40px;
        height: 40px;

        @media (max-width: 1023px) {
            margin-right: 16px;
        }
        border-radius: var(--radius-full);
        background: #f0f4f5;
    }

    &.skip {
        cursor: auto;
    }

    & i {
        color: var(--color-base-black);
    }
`;
