// @flow

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import apiMethod from "../../api/apiMethod";
import errors from "../../lib/errors";
import { __ } from "../../lib/translate";
import { MIN_USER_QUOTA, QUOTA_STEP, CHANGE_DATA_REGION_STATUS } from "@pcloud/web-utilities/dist/config/constants";

import InviteFamilyForm from "./InviteFamilyForm";
import { LoaderFamily } from "./SharedFamilyComponents";
import InviteFamilyModal from "../../components/Modals/InviteFamilyModal";
import ContentView from "../../components/ContentView/ContentView";
import { useDispatch } from "react-redux";
import FlowManagerWrapper from "./FlowManagerWrapper";
import { BROWSE_MEMBERS } from "../../config/constants";
import { loadContent, setContentItems } from "../../lib/state/reducers/contentSlice";

type Member = {
  userid: number,
  quota: number,
  email: string,
  isowner: boolean
};

type Invite = {
  email: string,
  invitationid: number,
  quota: number,
  invitelink: string
};

const FamilyContainer = () => {
  const dispatch = useDispatch();
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);
  const [maxMembers, setMaxMembers] = useState(0);
  const [relocationInProgress, setRelocationInProgress] = useState(false);
  const [isInviteFamilyMemberModalOpened, setIsInviteFamilyMemberModalOpened] = useState(false);
  const xhr = [];

  const { token, quota, usedQuota, relocationStatus } = useSelector((state) => {
    const { token, userinfo: { usedquota, quota } = {}, relocationInfo: { relocationStatus } = {} } = state.pCloudUser;
    return { token, usedQuota: usedquota, quota, relocationStatus };
  });
  const familyItems = useSelector((state) => state.content.itemData.items ? Object.values(state.content.itemData.items) : []);
  const noMembers = familyItems.length === 0;

  useEffect(() => {
    fetchMembers();
    return () => {
      xhr.forEach(x => "abort" in x && x.abort());
    };
  }, []);

  const fetchMembers = () => {
    setIsLoadingMembers(true);
    xhr.push(
      apiMethod(
        "fm_getinfo",
        { auth: token },
        res => {
          setIsLoadingMembers(false);
          setMaxMembers(res.data.maxmembers);

          const list = [...res.members, ...res.invites].filter((item) => !item.isowner);
          const items = [];
          for (const item of list) {
            const tmpItem = {
              id: item.email,
              email: item.email,
              status: item.invitationid ? 'Pending' : 'Active',
              quota: item.quota,
              isFamilySettings: true,
            };

            if (item.invitationid) {
              tmpItem.invitelink = item.invitelink;
              tmpItem.invitationid = item.invitationid;
            } else {
              tmpItem.isowner = item.isowner;
              tmpItem.userid = item.userid;
              tmpItem.usedquota = item.usedquota;
            }

            items.push(tmpItem);
          }

          dispatch(loadContent(true));
          dispatch(setContentItems({ contents: items }));
        },
        {
          errorCallback: ({ result, error }) => {
            setIsLoadingMembers(false);
            if (errors[result]) {
              HFN.message(errors[result], "error");
            } else {
              HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
              throw new Error(error);
            }
          }
        }
      )
    );
  };

  const hasAvailableQuota = () => {
    const freeQuota = quota - usedQuota;
    const minUserQuota = (quota % QUOTA_STEP) + MIN_USER_QUOTA;
    return freeQuota >= MIN_USER_QUOTA && quota > minUserQuota;
  };

  const canInvite = () => {
    return familyItems.length < maxMembers - 1;
  };

  const onSuccessInviteFamilyForm = (invite) => {
    dispatch(loadContent(true));
    dispatch(setContentItems({
      contents: [...familyItems, {
        id: invite.email,
        email: invite.email,
        status: 'Pending',
        quota: invite.quota,
        isFamilySettings: true,
        invitelink: invite.invitelink,
        invitationid: invite.invitationid,
      }]
    }));
    setIsInviteFamilyMemberModalOpened(false)
  };

  const removeMember = (id: number) => {
    dispatch(loadContent(true));
    dispatch(setContentItems({
      contents: familyItems.filter(({ userid }) => userid !== id)
    }));
  };

  const manageMember = (id: number, quota: number) => {
    dispatch(loadContent(true));
    dispatch(setContentItems({
      contents: familyItems.map((item) => item.userid === id ? { ...item, quota } : item)
    }));
  };

  const cancelInvite = (id: number) => {
    dispatch(loadContent(true));
    dispatch(setContentItems({
      contents: familyItems.filter(({ invitationid }) => invitationid !== id)
    }));
  };

  const renderHeader = () => {
    let header = "";
    let subHeader = "";

    if (noMembers) {
      header = __("family_no_members_header", "You don’t have any members");
      subHeader = __(
        "family_no_members_subheader",
        "You haven’t invited any members to your Family account."
      );
    } else if (!canInvite()) {
      header = __("family_no_invite_header", "You cannot invite more family members");
      subHeader = __(
        "family_no_invite_subheader_max_members",
        "You've reached the max. number of family members you can invite."
      );
    } else if (!hasAvailableQuota()) {
      header = __("family_no_invite_header", "You cannot invite more family members");
      subHeader = __(
        "family_no_invite_subheader_quota",
        "You don't have enough storage available to share with more family members."
      );
    } else {
      header = __("family_invite_header", "Invite your family members to join pCloud!");
      subHeader = __(
        "family_invite_subheader",
        "Enjoy all Premium features for you and up to five family members."
      );
    }

    return (
      <div>
        <Header>{header}</Header>
        <Subheader>{subHeader}</Subheader>
        {noMembers && <InviteFamilyForm onSuccessInviteFamilyForm={onSuccessInviteFamilyForm} />}
        {(canInvite() && !noMembers && hasAvailableQuota()) &&
          <StyledButton onClick={(e) => { setIsInviteFamilyMemberModalOpened(true) }}>{__("family_invite_member", "Invite family member")}</StyledButton>}
      </div>
    );
  };

  return isLoadingMembers ? (
    <LoaderFamily />
  ) : (
    <>
      <Wrapper>
        {renderHeader()}
        {relocationInProgress && <DisableMessage>{__("owner_relocation_invite_restriction")}</DisableMessage>}
        <InviteFamilyModal
          opened={isInviteFamilyMemberModalOpened}
          onClose={() => setIsInviteFamilyMemberModalOpened(false)}
          disabled={relocationInProgress}
          onSuccessInviteFamilyForm={onSuccessInviteFamilyForm}
          familyItems={familyItems}
        />
        <FlowManagerWrapper
          disabled={relocationInProgress}
          removeMember={removeMember}
          manageMember={manageMember}
          cancelInvite={cancelInvite}
        />
      </Wrapper>
      {!noMembers && <ContentViewContainer>
        <ContentView contentType={BROWSE_MEMBERS} />
      </ContentViewContainer>}
    </>
  );
};

export default FamilyContainer;

const Wrapper = styled.div`
  padding: 0 var(--spacing-md) 0 var(--spacing-md);
  position: relative;
  a {
    margin-top: 20px;
    min-width: 180px;
    height: 40px;
  }
`;

const Header = styled.div`
  font-family: Roboto;
  color: var(--text-primary, #282C2F);
  margin: var(--spacing-2xl) 0 var(--spacing-sm) 0;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
`;

const Subheader = styled.div`
  color: var(--text-primary, #282C2F);
  font-family: Roboto;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  margin-bottom: var(--spacing-2xl);
`;

const DisableMessage = styled.div`
  margin: 25px 0;
  padding: 19px 50px 17px 49px;
  border: solid 1px #fcf2d7;
  background-color: #fff8e2;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -0.2px;
  text-align: center;
  color: #000000;
`;

const StyledButton = styled.button`
  width: fit-content;
  border-radius: 20px;
  margin: 0 auto var(--spacing-2xl) auto;
  display: block;
  background: var(--Primary-500, #17BED0);
  height: 40px;
  color: var(--color-base-white);
  border: none;
  outline: none;
  padding: var(--spacing-sm, 8px) var(--spacing-md);
  font-size: 15px;
  font-family: Roboto;
  cursor: pointer;
`;

const ContentViewContainer = styled.div`
  height: 327px;
  padding: 0 var(--spacing-md);

  @media (max-width: 1024px) {
    height: 278px;
    padding: 0;
    margin-top: var(--spaces-8);
  };
`;