// @flow

import React, { PureComponent, useEffect, useState } from "react";
import Componentify from "react-componentify";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import apiMethod from "../../api/apiMethod";
import { boldConverter, brTagConverter } from "../../lib/componentifyConverters";
import { __ } from "../../lib/translate";
import { getHash, isSameLocation, getLocationFromUrl } from "../../lib/utils";
import { renderQuota } from "@pcloud/web-utilities/dist/plans/utils.plans";
import { URLMY } from "@pcloud/web-utilities/dist/config";

import Button from "../../components/ButtonDefault";
import InputText from "../../components/InputText";
import RegistrationForm, { ErrorMessage } from "@pcloud/web-utilities/dist/authentication/components/UserAuthentication2/InvitationRegistration/RegistrationForm";
import * as Style from "../SharedInvitationComponents";
import WarningInvitationModal from "./WarningInvitationModal";
import BusinessInviteIcon from "../../../root/img/svg/business_invite.svg";
import WarnIcon from "../../../root/img/svg/warning_invitation.svg";

import type { SuccessData } from "./types";
import { setGlobalLocationById } from "@pcloud/web-utilities/dist/api/utils.api";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";
import { productNameMap } from "@pcloud/web-utilities/dist/plans/plansInfo";
import { BUSINESS_PLANS_ID_MAP_FROM_API } from "@pcloud/web-utilities/dist/plans/constants.plans.js";
import { login } from "@pcloud/web-utilities/dist/lib/state/reducers/pcloud/userSlice"

const BusinessInvitation = () => {
  const user = useSelector(({ pCloudUser }) => pCloudUser);
  const {
    logged,
    token,
    userinfo
  } = user;
  const {
    subscriptions,
    premium,
    premiumlifetime,
    cryptosubscription,
    cryptolifetime,
    plan,
    business,
    email,
    language
  } = userinfo;
  const dispatch = useDispatch();

  const businessBillingInfo = useSelector(({ business }) => business.billingInfo);
  const businessAccountInfo = useSelector(({ business }) => business.accountInfo);
  const isBusiness = useSelector(({ pCloudUser }) => pCloudUser.userinfo.business);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [account, setAccount] = useState("");
  const [quota, setQuota] = useState(0);
  const [sender, setSender] = useState({
    position: "",
    firstName: "",
    lastName: ""
  });
  const [invitedEmail, setInvitedEmail] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorJoin, setErrorJoin] = useState("");
  const [shouldOpenWarningModal, setShouldOpenWarningModal] = useState(false);
  const [newRegistration, setNewRegistration] = useState(false);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    if (!newRegistration) {
      setTimeout(() => {
        getInviteInfo();
      }, 500);
    }
  }, [logged, newRegistration]);

  const onRegistration = ({ action, userinfo, firstName, lastName }: SuccessData) => {
    if (action === "register") {
      setLoading(true);
      setShouldOpenWarningModal(false);
      setNewRegistration(true);
    } else {
      setLoading(false);
      setFirstName(firstName);
      setLastName(lastName);
    }

    dispatch(login({userinfo: userinfo, token: userinfo.auth}));
    HFN.userLoginAfter(userinfo, () => {
      if (action === "register") {
        join(firstName, lastName, userinfo.auth);
        setShowMessage(false);
      }
    });
  }

  const getInviteInfo = () => {
    const code = getHash("code");
    setLoading(true);

    apiMethod(
      "account_inviteinfo",
      { code },
      ({ account, invited_mail, sender, quota }) => {
        let errorMessage = "";
        let shouldOpenWarningModal = shouldRenderWarningModal();

        if (logged && !isSameLocation()) {
          errorMessage = __("business_invite_subscribed_message4");
          shouldOpenWarningModal = false;
        }

        setCode(code);
        setAccount(account);
        setQuota(quota);
        setSender({
          position: sender.position,
          firstName: sender.firstname,
          lastName: sender.lastname
        })
        setInvitedEmail(invited_mail);
        setLoading(false);
        setShouldOpenWarningModal(shouldOpenWarningModal);
        setError(errorMessage);
      },
      {
        showErrorMessage: false,
        errorCallback: ({ result, error }) => {
          if (result == 2012) {
            setLoading(false);
            setError(__("family_invite_cancelled_title"));
          } else if (result == 2336) {
            setLoading(false);
            setError(__("business_member_invite_relocation"));
          } else {
            setLoading(false);
            setError(error);
          }
        }
      }
    );
  }

  const join = (firstName = "", lastName = "", token) => {
    if (firstName.length === 0) {
      setErrorJoin(__("error_enter_fName"))
      return;
    } else if (lastName.length === 0) {
      setErrorJoin(__("error_enter_lName"))
      return;
    }

    setLoading(true);

    apiMethod(
      "account_inviteaccept",
      {
        auth: token,
        code,
        firstname: firstName,
        lastname: lastName,
        termsaccepted: "yes"
      },
      () => {
        window.open(URLMY, "_self");
      },
      {
        errorCallback: ({ error }) => {
          setLoading(false);
          HFN.message(error, "error");
        }
      },
      { forceFresh: true }
    );
  }

  const onModalClose = () => {
    setShouldOpenWarningModal(false);
  }

  const onLogout = () => {
    HFN.logout();
    setGlobalLocationById(getLocationFromUrl().id);
  }

  const getPlanName = () => {
    if (!isBusiness || !businessAccountInfo.billing) {
      return null;
    }

    const businessPlanId = BUSINESS_PLANS_ID_MAP_FROM_API[businessAccountInfo.billing.businessplanid];
    let period = "";

    if (businessBillingInfo?.is_trial) {
      period = __("free_trial", "Free Trial");
    } else if (businessBillingInfo?.billing?.period === 0) {
      period = __("Monthly");
    } else if (businessBillingInfo?.billing?.period === 1) {
      period = __("annual_payment", "Annual");
    }

    return `${productNameMap[businessPlanId]?.long} ${period ? "(" + period + ")" : ""}`;
  }

  const shouldRenderWarningModal = () => {
    const hasSubscription = subscriptions && subscriptions.length > 0;

    return (plan === 0 || hasSubscription) && !cryptolifetime && !business && !newRegistration;
  }

  const changeFirstName = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFirstName(value);
  }

  const changeLastName = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLastName(value);
  }

  const renderLoading = () => {
    return (
      <Style.LoadingWrapper>
        <div />
      </Style.LoadingWrapper>
    );
  }

  const renderNamesInput = () => {
    return (
      <InputWrapper>
        <ErrorMessage>{errorJoin}</ErrorMessage>
        <InputText type="fName" name="fName" value={firstName} onChange={changeFirstName} placeholder={__("First Name")} />
        <InputText type="lName" name="lName" value={lastName} onChange={changeLastName} placeholder={__("Last Name")} />
      </InputWrapper>
    );
  }

  const renderMessages = (error) => {
    const hasSubscription = subscriptions && subscriptions.length > 0;
    const hasEditablePrSubscription =
      subscriptions &&
      subscriptions.some(subscription => {
        const premiumSubscription = subscription.products.indexOf(1) == -1 || subscription.products.indexOf(3) == -1;
        return subscription.canedit == true && premiumSubscription;
      });
    const hasPremiumLifetime = premiumlifetime && (plan == 1 || plan == 3);
    const hasCryptoLifetime = cryptolifetime;
    const hasPremiumSubscription = premium && !premiumlifetime;
    const hasCryptoSubscrioption = cryptosubscription && !cryptolifetime;
    const hasFamilyPlan = plan == 14 || plan == 15;
    const hasBusiness = business;
    const planName = getPlanName();

    let Icon = WarnIcon;
    let MessageComponent = Style.WarningMessage;
    let title = __("family_invite_subscribed_header");
    let message;
    let showButtons;
    let messageRequireBusinessPlan = false;

    if (error) {
      title = "";
      message = error;
      showButtons = false;
    } else if (logged && !isSameLocation()) {
      title = "";
      message = __("business_invite_subscribed_message4");
      showButtons = false;
    } else if (hasPremiumLifetime || hasBusiness || hasFamilyPlan) {
      message = __("business_invite_subscribed_message3");
      showButtons = false;
      messageRequireBusinessPlan = true;
    } else if (hasCryptoLifetime) {
      message = __("business_invite_subscribed_message3").replace("%planname%", "Crypto Lifetime");
      showButtons = false;
    } else if (hasBusiness) {
      message = __("family_access_denied");
      showButtons = false;
    } else if (hasSubscription && !hasEditablePrSubscription) {
      message = __("business_invite_subscribed_message2");
      showButtons = true;
      messageRequireBusinessPlan = true;
    } else if (hasPremiumSubscription) {
      message = __("business_invite_subscribed_message1");
      showButtons = true;
      messageRequireBusinessPlan = true;
    } else if (hasCryptoSubscrioption) {
      message = __("business_invite_subscribed_message1").replace("%planname%", "Crypto");
      showButtons = true;
    } else {
      title = __("pCloud Business");
      message = __("business_invite_subscribed_message1");
      Icon = BusinessInviteIcon;
      MessageComponent = Style.Message;
      showButtons = true;
      messageRequireBusinessPlan = true;
    }

    message = message.replace("%planname%", planName);

    return (
      <Style.Container>
        <Style.HeaderLogoContainer>
          <PcloudLogo />
        </Style.HeaderLogoContainer>
        <Style.Title>{title}</Style.Title>
        {(!messageRequireBusinessPlan || planName) ? (
            <MessageComponent>
              <Icon fill="#ff9700" />
              <Style.ComponentifyWrapper>
                <Componentify text={message} converters={[boldConverter, brTagConverter]} />
              </Style.ComponentifyWrapper>
            </MessageComponent>
        ) : renderWelcomeInviteMessage()}


        {showButtons ? (
          <ButtonsWrapper>
            {renderNamesInput()}
            <JoinButton>
              <Button onClick={() => join(firstName, lastName, token)}>{__("Join")}</Button>
            </JoinButton>
          </ButtonsWrapper>
        ) : null}
        {logged ? <Style.Link onClick={onLogout}>{__("family_invite_subscribed_login")}</Style.Link> : null}
      </Style.Container>
    );
  }

  const renderWelcomeInviteMessage = () => {
    const message = __(
        "business_invite_subheadline_new",
        "You have been invited by <b>%firstname% %lastname%</b> to join %account%'s Business account and get <b>%businessquota%</b> secure storage."
    )
        .replace("%firstname%", sender.firstName)
        .replace("%lastname%", sender.lastName)
        .replace("%account%", account)
        .replace("%businessquota%", renderQuota(quota));

    return (
        <Style.Message>
          <BusinessInviteIcon width="32" fill="#17bed0" />
          <Style.ComponentifyWrapper>
            <Componentify text={message} converters={[boldConverter, brTagConverter]} />
          </Style.ComponentifyWrapper>
        </Style.Message>
    );
  }

  const renderRegister = () => {
    const locationFromUrl = getLocationFromUrl() || {};

    return (
      <Style.Container>
        <Style.HeaderLogoContainer>
          <PcloudLogo />
        </Style.HeaderLogoContainer>
        <Style.Title>{__("pCloud Business")}</Style.Title>
        {renderWelcomeInviteMessage()}
        <Style.Wrapper className="business-invitation-reg-wrapper">
          <RegistrationForm
            title={__("family_invite_description", "Log in or create a new account to continue")}
            formWidth="360px"
            language={language}
            email={invitedEmail}
            shouldRednerNames
            noDefaultFiles
            locations={[locationFromUrl]}
            initLoginRegModal={HFN.initLoginRegModal.bind(HFN)}
            onSuccess={onRegistration}
          />
        </Style.Wrapper>
      </Style.Container>
    );
  }

  let content = null;

  if (loading) {
    content = renderLoading();
  } else if ((logged && showMessage) || error) {
    content = renderMessages(error);
  } else if (!logged) {
    content = renderRegister();
  }

  return (
    <React.Fragment>
      {content}
      <WarningInvitationModal email={email} isOpen={shouldOpenWarningModal} onCloseModal={onModalClose} />
    </React.Fragment>
  );

}

export default BusinessInvitation;

const InputWrapper = styled.div`
  input.text {
    width: 100%;
    margin: 0 0 15px 0;
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ccc;
    font-size: 14px;
    border-radius: 5px;
    box-sizing: border-box;
    min-width: initial;
    width: 100%;
    color: #000;
  }
`;

const ButtonsWrapper = styled.div`
  display: table;
  max-width: 360px;
  margin: 10px auto;
  text-align: center;
`;

const JoinButton = styled(Style.ButtonContainerInline)`
  a {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;
