import { useState, useEffect, useRef } from "react";
import {
    combineQueues,
    generateShuffleQueue,
    getFileIds,
    getMinifyData, sortQueue,
    timeDifference
} from "../helpers";
import {
    REPEAT_STATE_INACTIVE,
    REPEAT_STATE_REPEAT,
    REPEAT_STATE_REPEAT_ONE
} from "../constants";
import { detectSafari, fileext, rcookie } from "../../../lib";
import apiMethod from "../../../api/apiMethod";
import { useDispatch, useSelector } from "react-redux";
import { addLoadedSong } from "../../../lib/state/reducers/audioPlayerSlice";

type Props = {
    list: Array<any>,
    playItemId: number,
    playlist: Object<any>
};

const usePlayer = (props: Props) => {
    const isMobile = HFN.config.isMobile();

    const {
        list = [],
        playItemId = undefined,
        isSingle = false,
        isPublic = false,
        data = {}
    } = props;

    const audioRef = useRef();
    const [initialProgress, setInitialProgress] = useState(props.progress ?? 0);
    const [playlist, setPlaylist] = useState({});
    const [volume, setVolume] = useState(0.5);
    const [currentSong, setCurrentSong] = useState({});
    const [queue, setQueue] = useState([]);
    const [shuffleQueue, setShuffleQueue] = useState([]);
    const [duration, setDuration] = useState(0);
    const [isReady, setIsReady] = useState(false);
    const [isSingleSongPlayer] = useState(isSingle);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playlistItems, setPlaylistItems] = useState([]);
    const [isShuffle, setIsShuffle] = useState(false);
    const [repeatState, setRepeatState] = useState(REPEAT_STATE_INACTIVE);
    const [analyzerData, setAnalyzerData] = useState(null);
    const [podcastMode, setPodcastMode] = useState(false);
    const [playRate, setPlayRate] = useState(1);
    const [sleepTimer, setSleepTimer] = useState(0);
    const [restoreState, setRestoreState] = useState(
        props.restoreState ?? false
    );

    const dispatch = useDispatch();
    const loadedSongs = useSelector(
        ({ audioPlayer }) => audioPlayer.loadedSongs
    );
    const itemData = useSelector(({ content }) => content.itemData);
    const itemKeys = useSelector(({ content }) => content.itemKeys);

    const [currentSongItemId, setCurrentSongItemId] = useState(null);
    const [volumeBeforeMute, setVolumeBeforeMute] = useState(0);

    useEffect(() => {
        const cookieVolume = localStorage.getItem("uvolume") ?? 0.5;
        setVolume(cookieVolume);
        setPlaylist(props.playlist);
    }, [props.playlist]);

    useEffect(() => {
        if (playItemId) {
            setCurrentSongItemId(playItemId);
        }
    }, [playItemId]);

    useEffect(() => {
        if (
            !props.restoreState &&
            playItemId &&
            playItemId === currentSongItemId
        ) {
            audioRef.current?.play();
        }
    }, [props.restoreState]);

    useEffect(() => {
        _updateQueue(queue.length);
    }, [list]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.playbackRate = playRate;
        }
    }, [playRate, isReady]);

    useEffect(() => {
        let timer;

        if (sleepTimer > 0) {
            timer = setTimeout(() => {
                audioRef.current?.pause();
                setSleepTimer(0);
            }, sleepTimer * 60 * 1000);
        }

        return () => clearTimeout(timer);
    }, [sleepTimer]);

    useEffect(() => {
        if (isSingle || !queue.length) {
            return;
        }

        if (isPublic && data.code) {
            localStorage.setItem("playerpcode", data.code);
        } else {
            localStorage.removeItem("playerpcode");
            localStorage.setItem("playerformail", rcookie("email"));
        }

        if (playlist) {
            localStorage.setItem("playerplist", playlist.id);
            localStorage.removeItem("playerqueue");
        } else {
            const ids = getFileIds(queue, true);
            localStorage.setItem("playerqueue", JSON.stringify(ids));
            localStorage.removeItem("playerplist");
        }
    }, [queue, playlist]);

    useEffect(() => {
        if (isSingle) {
            return;
        }

        let interval;

        const writeCurrentSong = () => {
            localStorage.setItem(
                "playercurrent",
                JSON.stringify({
                    item: currentSong.fileid,
                    progress: audioRef.current?.currentTime ?? 0
                })
            );
        };

        if (isPlaying) {
            interval = setInterval(writeCurrentSong, 1000);
            writeCurrentSong();
        }

        return () => clearInterval(interval);
    }, [currentSong.fileid, isPlaying]);

    useEffect(() => {
        if (
            currentSongItemId === currentSong?.fileid ||
            !currentSongItemId ||
            !queue ||
            !queue.length
        ) {
            return;
        }

        const targetItem = queue.find((item) => {
            return item.fileid === currentSongItemId;
        });

        if (targetItem) {
            if (!currentSongItemId) {
                return;
            }

            if (!targetItem.hasOwnProperty("name")) {
                loadQueueItem(targetItem.fileid, true, false, false);
            } else {
                _processNewSong(targetItem);
            }
        } else {
            _updateQueue();
        }
    }, [currentSongItemId, queue]);

    const _processNewSong = (item, unloadItem = false) => {
        if (item.audiolink) {
            _handleSetCurrentSong(item, item.audiolink, unloadItem);
            return;
        }

        if (loadedSongs[currentSongItemId]) {
            const newSong = Object.assign(item, loadedSongs[currentSongItemId]);
            const loadedObject = loadedSongs[currentSongItemId];
            const { hours } = timeDifference(loadedObject.time, Date.now());

            if (hours > 2) {
                reloadAudio(newSong, false);
            } else {
                setCurrentSong(newSong);

                if (restoreState) {
                    setRestoreState(false);
                } else {
                    setTimeout(() => {
                        audioRef.current?.load();
                        const play = audioRef.current?.play();
                        if (audioRef.current) {
                            audioRef.current.playbackRate = playRate;
                        }
                        if (play) {
                            play.catch(() => {});
                        }
                    }, 100);
                }
                if (isReady) {
                    audioRef.current.playbackRate = playRate;
                }
            }
        } else {
            _getLinkFromApi(item, (ret, disableDuration = false) => {
                _handleSetCurrentSong(
                    item,
                    HFN.prepUrl(ret),
                    unloadItem,
                    disableDuration
                );
            });
        }

        _preLoadSongs();
    };

    const _getLinkFromApi = (item, callback = null, forceFresh = false) => {
        let endpointUrl;
        let disableDuration = false;
        const params = { fileid: item.fileid };

        if (data.code) {
            const audioExtVariants = ["wma", "aif", "aiff", "opus"];

            if (!detectSafari()) {
                audioExtVariants.push("m4a");
            }

            if (
                data.candownload &&
                !audioExtVariants.includes(fileext(item.name).toLowerCase())
            ) {
                endpointUrl = "getpublinkdownload";
            } else {
                endpointUrl = "getpubaudiolink";
                disableDuration = audioExtVariants.includes(fileext(item.name).toLowerCase());
            }

            params.code = data.code;
            params.linkpassword = data.linkpassword;
        } else {
            const audioExtVariants = [
                "mp3",
                "flac",
                "mp4",
                "wav",
                "aac",
                "ogg",
                "opus"
            ];

            if (detectSafari()) {
                audioExtVariants.push('m4a');
                audioExtVariants.push('aif');
                audioExtVariants.push('aiff');
            }

            if (audioExtVariants.includes(fileext(item.name).toLowerCase())) {
                endpointUrl = "getfilelink";
            } else {
                endpointUrl = "getaudiolink";
                disableDuration = true;
            }
        }

        params.forceFresh = forceFresh;
        apiMethod(endpointUrl, params, function(ret) {
            if (callback) {
                callback(ret, disableDuration);
            }
        });
    };

    const _preLoadSongs = () => {
        const fileIds = [];
        if (isShuffle) {
            const shuffleFileId = shuffleQueue[0];
            const shuffleIndex = queue.findIndex((item) => {
                return item.fileid === shuffleFileId;
            });
            fileIds.push(queue[shuffleIndex]);
        } else {
            const index = queue.findIndex(function(item) {
                return item.fileid === currentSongItemId;
            });
            const nextSongIndex = queue.length - 1 === index ? 0 : index + 1;
            fileIds.push(queue[nextSongIndex]);
        }
        fileIds.forEach((item) => {
            if (!item.hasOwnProperty("name")) {
                loadQueueItem(item.fileid, false, true);
            } else {
                _preLoadSong(item);
            }
        });
    };

    const _preLoadSong = (item) => {
        if (loadedSongs[item.fileid]) {
            return;
        }

        _getLinkFromApi(item, (ret, disableDuration = false) => {
            dispatch(addLoadedSong({
                song: {
                    src: HFN.prepUrl(ret),
                    disableDuration: disableDuration,
                    time: Date.now()
                },
                id: item.fileid
            }));
        });
    };

    const _handleSetCurrentSong = (
        song,
        url,
        unloadItem = false,
        disableDuration = false
    ) => {
        const songSource = {
            src: url,
            disableDuration: disableDuration,
            time: Date.now()
        };
        dispatch(addLoadedSong({
            song: songSource,
            id: song.fileid
        }));
        setCurrentSong(Object.assign(song, songSource));
        audioRef.current?.load();
        setTimeout(() => {
            if (initialProgress && audioRef.current) {
                audioRef.current.currentTime = initialProgress;
                setInitialProgress(0);
                setRestoreState(false);
            } else {
                audioRef.current?.load();
                const play = audioRef.current?.play();
                if (audioRef.current) {
                    audioRef.current.playbackRate = playRate;
                }
                if (play) {
                    play.catch(() => {});
                }
            }
        }, 100);
        if (isReady) {
            audioRef.current.playbackRate = playRate;
        }

        if (unloadItem) {
            const tmpQueueIndex = queue.findIndex((item) => {
                return item.fileid === song.fileid;
            });
            if (tmpQueueIndex >= 0) {
                const tmpQueueList = [...queue];
                tmpQueueList[tmpQueueIndex] = song;
                setQueue(tmpQueueList);
            }
        }
    };

    const _updateQueue = () => {
        const newList = sortQueue(list, itemKeys);

        if (playItemId) {
            if (!props.playlist) {
                setPlaylist(undefined);
            }
            setQueue(newList);
            return;
        }

        // Insert new song into queue
        const newQueue = combineQueues(queue, newList);
        if (newQueue.length !== queue.length) {
            setQueue(newQueue);
            setShuffleQueue(generateShuffleQueue(newQueue, currentSong));
        }
    };

    const _getShuffleIndex = (isNextDirection) => {
        if (!shuffleQueue.length) {
            return null;
        }
        let tmpShuffleQueue = [...shuffleQueue];
        let nextSong;

        if (isNextDirection) {
            nextSong = tmpShuffleQueue[0];
            tmpShuffleQueue.shift();
            tmpShuffleQueue.push(nextSong);

            setShuffleQueue(tmpShuffleQueue);
        } else {
            const currentShuffleSongIndex = tmpShuffleQueue.findIndex(
                (item) => {
                    return item === currentSong.fileid;
                }
            );
            nextSong =
                tmpShuffleQueue[
                    currentShuffleSongIndex === 0
                        ? tmpShuffleQueue.length - 1
                        : currentShuffleSongIndex - 1
                ];
        }

        return queue.findIndex((item) => {
            return item.fileid === nextSong;
        });
    };

    const reloadAudio = (song = null, restoreState = true) => {
        if (!song) {
            song = currentSong;
        }
        _getLinkFromApi(song, (ret, disableDuration = false) => {
            const src = HFN.prepUrl(ret);
            dispatch(addLoadedSong({
                song: {
                    src: src,
                    disableDuration: disableDuration,
                    time: Date.now()
                },
                id: song.fileid
            }));
            const source = document.getElementById("PlayerSource");
            const currentTime = audioRef.current.currentTime;
            source.src = src;
            audioRef.current.load();
            if (restoreState) {
                audioRef.current.currentTime = currentTime;
            }
            const play = audioRef.current?.play();
            if (play) {
                play.catch(() => {});
            }
            setIsPlaying(true);

            song.src = src;
            song.disableDuration = disableDuration;
            setCurrentSong(song);
        });
    };

    const _setLoadedItem = (
        data,
        fileId,
        playIt = false,
        preLoad = false,
        updateQueue = true
    ) => {
        let item = getMinifyData(data);

        if (updateQueue) {
            const tmpQueueIndex = queue.findIndex((item) => {
                return item.fileid === fileId;
            });
            if (tmpQueueIndex >= 0) {
                const tmpQueueList = [...queue];
                tmpQueueList[tmpQueueIndex] = item;
                setQueue(tmpQueueList);
            }
        }

        if (playIt) {
            _processNewSong(item, true);
        }

        if (preLoad) {
            _preLoadSong(item);
        }
    };

    const loadQueueItem = (
        fileId,
        playIt = false,
        preLoad = false,
        updateQueue = true
    ) => {
        if (itemData && itemData.hasOwnProperty("items")) {
            let i;
            for (i in itemData.items) {
                if (itemData.items[i].fileid === fileId) {
                    _setLoadedItem(
                        itemData.items[i],
                        fileId,
                        playIt,
                        preLoad,
                        updateQueue
                    );
                    return;
                }
            }
        }

        apiMethod(
            "stat",
            { fileid: fileId },
            function(ret) {
                if (ret.metadata) {
                    _setLoadedItem(
                        ret.metadata,
                        fileId,
                        playIt,
                        preLoad,
                        updateQueue
                    );
                }
            },
            {
                errorCallback: function() {
                    HFN.audioPlayer.destroy();
                }
            }
        );
    };

    const onTogglePlayPause = (e) => {
        e.stopPropagation();

        if (isPlaying) {
            audioRef.current?.pause();
            setIsPlaying(false);
        } else {
            const loadedObject = loadedSongs[currentSongItemId];
            const { hours } = timeDifference(loadedObject.time, Date.now());

            if (hours > 2) {
                reloadAudio();
            } else {
                const play = audioRef.current?.play();
                const remainingTime = duration - audioRef.current.currentTime;
                if (remainingTime > 0 && remainingTime < 1) {
                    onNext(e);
                    return;
                }
                if (play) {
                    play.catch(() => {});
                }
                setIsPlaying(true);
            }
        }
    };

    const onVolumeChange = (volumeValue) => {
        if (!audioRef.current || isNaN(volumeValue)) return;
        audioRef.current.volume = volumeValue;
        setVolume(volumeValue);
    };

    const onVolumeChangeEnd = (volumeValue) => {
        localStorage.setItem("uvolume", volumeValue);
    };

    const onToggleRepeat = () => {
        let nextState;

        if (repeatState === REPEAT_STATE_INACTIVE) {
            nextState = REPEAT_STATE_REPEAT;
        } else if (repeatState === REPEAT_STATE_REPEAT) {
            nextState = REPEAT_STATE_REPEAT_ONE;
        } else {
            nextState = REPEAT_STATE_INACTIVE;
        }

        setRepeatState(nextState);
    };

    const onToggleShuffle = () => {
        const tmpIsShuffle = !isShuffle;
        setIsShuffle(tmpIsShuffle);

        if (tmpIsShuffle) {
            setShuffleQueue(generateShuffleQueue(queue, currentSong));
        } else {
            setShuffleQueue([]);
        }
    };

    const onMuteUnmute = () => {
        if (!audioRef.current) return;

        if (audioRef.current.volume === 0) {
            onVolumeChange(volumeBeforeMute ?? 1);
        } else {
            setVolumeBeforeMute(audioRef.current.volume);
            onVolumeChange(0);
        }
    };

    const onPrev = (e) => {
        if (e) {
            e.stopPropagation();
        }
        const index = queue.findIndex(function(item) {
            return item.fileid === currentSongItemId;
        });

        const nextSongIndex =
            isShuffle && queue.length > 1
                ? _getShuffleIndex(false)
                : index === 0
                ? queue.length - 1
                : index - 1;

        if (queue[nextSongIndex].fileid !== currentSongItemId) {
            onSelectSong(queue[nextSongIndex].fileid);
        } else {
            audioRef.current.currentTime = 0;
            audioRef.current.playbackRate = playRate;
        }
    };

    const onNext = (e, isAuto = false) => {
        if (e) {
            e.stopPropagation();
        }

        if (repeatState === REPEAT_STATE_REPEAT_ONE) {
            audioRef.current.currentTime = 0;
            const play = audioRef.current?.play();
            if (play) {
                play.catch(() => {});
            }
            return;
        }

        const index = queue.findIndex(function(item) {
            return item.fileid === currentSongItemId;
        });

        const isEnd = queue.length - 1 === index;

        if (
            isEnd &&
            repeatState === REPEAT_STATE_INACTIVE &&
            !isShuffle &&
            isAuto
        ) {
            audioRef.current?.pause();
            return;
        }

        let nextSongIndex =
            isShuffle && queue.length > 1
                ? _getShuffleIndex(true)
                : isEnd
                ? 0
                : index + 1;

        if (queue[nextSongIndex].fileid !== currentSongItemId) {
            onSelectSong(queue[nextSongIndex].fileid);
        } else {
            audioRef.current.currentTime = 0;
            const play = audioRef.current?.play();
            if (play) {
                play.catch(() => {});
            }
        }
    };

    const onSelectSong = (id) => {
        setCurrentSongItemId(id);
    };

    const onRemoveFromQueue = (e, id) => {
        if (queue.length > 1) {
            if (currentSongItemId === id) {
                onNext(e);
            }
        }

        setQueue(
            [...queue].filter((item) => {
                return item.fileid !== id;
            })
        );

        if (isShuffle) {
            let tmpShuffleQueue = [...shuffleQueue];
            const songIndex = tmpShuffleQueue.findIndex((item) => {
                return item === id;
            });
            tmpShuffleQueue.splice(songIndex, 1);
            setShuffleQueue(tmpShuffleQueue);
        }
    };

    const onCreatePlaylist = (name, callback = () => {}) => {
        let fileIdsArray = queue.map((item) => item.fileid);
        const params = {
            type: 1,
            name: name,
            fileids: fileIdsArray.join(",")
        };
        apiMethod(
            "collection_create",
            params,
            function(ret) {
                HFN.message("Playlist " + htmlentities(name) + " Created.");
                setPlaylist(ret.collection);
                callback();
                onLoadPlaylistItems(true);
            },
            {
                type: "post",
                errorCallback: function(ret) {
                    HFN.message(ret.error, "error");
                    callback();
                }
            }
        );
    };

    const onLoadPlaylist = (id, callback = () => {}) => {
        apiMethod(
            "collection_details",
            { collectionid: id, iconformat: "id" },
            function(ret) {
                if (ret.collection.contents.length) {
                    setQueue(ret.collection.contents);
                    setCurrentSongItemId(ret.collection.contents[0].fileid);
                    setPlaylist(ret.collection);
                    if (isShuffle) {
                        setShuffleQueue(
                            generateShuffleQueue(queue, currentSong)
                        );
                    }
                    callback();
                } else {
                    HFN.message("Playlist is empty", "error");
                }
            }
        );
    };

    const onSharePlaylist = () => {
        if (!playlist) {
            HFN.message("No playlist selected", "error");
            return;
        }
        const params = Object.assign({}, playlist);
        params.isplaylist = true;

        if (isMobile) {
            HFN.getPublink(
                params,
                function(link) {
                    if (link) {
                        if (
                            navigator.userActivation.isActive &&
                            typeof navigator.share === "function"
                        ) {
                            navigator.share({
                                title: playlist.name,
                                url: link.link
                            });
                        } else {
                            window.open(link.link);
                        }
                    } else {
                        apiMethod(
                            "getcollectionpublink",
                            { collectionid: params.id },
                            function(ret) {
                                if (
                                    navigator.userActivation.isActive &&
                                    typeof navigator.share === "function"
                                ) {
                                    navigator.share({
                                        title: playlist.name,
                                        url: ret.link
                                    });
                                } else {
                                    window.open(ret.link);
                                }
                            },
                            {
                                async: false,
                                errorCallback: function(ret) {
                                    if (HFN.ERROR_MESSAGE[ret.result]) {
                                        HFN.message(
                                            __(HFN.ERROR_MESSAGE[ret.result]),
                                            "error"
                                        );
                                    } else {
                                        HFN.message(ret.error, "error");
                                    }
                                }
                            }
                        );
                    }
                },
                { async: false }
            );
        } else {
            HFN.getPublink(params, function(link) {
                if (link) HFN.sharePublink(link);
                else HFN.createPublink(params);
            });
        }
    };

    const onLoadPlaylistItems = (forceFresh = false) => {
        apiMethod("collection_list", { forceFresh: forceFresh }, (ret) => {
            const userPlaylist = ret.collections.filter((item) => {
                return !item.system;
            });
            setPlaylistItems(userPlaylist);
        });
    };

    const onTogglePodcastMode = () => {
        setPodcastMode(!podcastMode);
    };

    const onPrevProgress = (e) => {
        e.stopPropagation();
        audioRef.current.currentTime = Math.max(
            0,
            audioRef.current.currentTime - 15
        );
        if (!isPlaying) {
            const play = audioRef.current?.play();
            if (play) {
                play.catch(() => {});
            }
        }
    };

    const onNextProgress = (e) => {
        e.stopPropagation();
        audioRef.current.currentTime = Math.min(
            audioRef.current.duration,
            audioRef.current.currentTime + 15
        );
        if (!isPlaying) {
            const play = audioRef.current?.play();
            if (play) {
                play.catch(() => {});
            }
        }
    };

    return {
        audioRef,
        currentSong,
        queue,
        isReady,
        isPlaying,
        volume,
        duration,
        playlist,
        playlistItems,
        isShuffle,
        repeatState,
        analyzerData,
        podcastMode,
        playRate,
        sleepTimer,
        isSingleSongPlayer,

        setAnalyzerData,
        setIsReady,
        setDuration,
        setIsPlaying,
        setQueue,
        setPlayRate,
        setSleepTimer,

        loadQueueItem,
        onPrev,
        onNext,
        onPrevProgress,
        onNextProgress,
        onVolumeChange,
        onVolumeChangeEnd,
        onMuteUnmute,
        onSelectSong,
        onToggleRepeat,
        onToggleShuffle,
        onTogglePlayPause,
        onRemoveFromQueue,
        onCreatePlaylist,
        onLoadPlaylist,
        onSharePlaylist,
        onLoadPlaylistItems,
        onTogglePodcastMode
    };
};

export default usePlayer;
