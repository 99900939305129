import React from "react";
import styled from "styled-components";
import Modal, { OvalModalCloseV2, OvalModalWrap } from "../Modal";
import InviteFamilyForm from "../../containers/FamilyPlan/InviteFamilyForm";

const InviteFamilyModal = ({ opened = false, onClose = () => { }, disabled, onSuccessInviteFamilyForm, familyItems = [] }) => {

  return (
    <Modal align="top" opened={opened} onClose={onClose}>
      <ModalWrapper>
        <OvalModalCloseV2 onClick={onClose} />
        <InviteFamilyForm
          disabled={disabled}
          isInModal={true}
          onClose={onClose}
          onSuccessInviteFamilyForm={onSuccessInviteFamilyForm}
          familyItems={familyItems}
        />
      </ModalWrapper>
    </Modal>
  )
}

export default InviteFamilyModal;

const ModalWrapper = styled(OvalModalWrap)`
  border-radius: 16px;
  width: 100%;
  max-width: 560px;
  width: calc(100vw - 20px);
  padding: var(--spacing-lg);
  box-sizing: border-box;
  display: block;

  @media (max-width: 500px) {
    padding: var(--spacing-md);
  }
`;