// @flow

import React, { useState, useCallback, useSelector } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { __ } from "../../lib/translate";
import { formatSizeInGb } from "../../lib/utils";
import { QUOTA_STEP } from "@pcloud/web-utilities/dist/config/constants";

import Slider from "../../components/Slider";

type Props = {
  minStartValue: number,
  startValue: number,
  maxValue: number,
  valueBoxColor?: string,
  shouldDisableSlider?: boolean,
  getQuota: number => void,
  isModal?: boolean,
};

const StorageManagement = ({
  minStartValue,
  startValue,
  maxValue,
  valueBoxColor,
  shouldDisableSlider = false,
  getQuota,
  isModal = false,
}: Props) => {
  const [storage, setStorage] = useState < number > (0);

  const getStorageValue = useCallback(
    (storage: number) => {
      setStorage(storage);
      getQuota(storage);
    },
    [getQuota]
  );

  const formattedStorage = formatSizeInGb(storage, 0);

  console.log("startValue", formatSizeInGb(startValue));
  console.log("maxValue", formatSizeInGb(maxValue));
  console.log("minStartValue", formatSizeInGb(minStartValue));

  return (
    <Container>
      <StyledSlider
        minValue={0}
        startValue={startValue}
        minStartValue={minStartValue}
        maxValue={maxValue}
        step={QUOTA_STEP}
        disabled={shouldDisableSlider}
        getSliderValue={getStorageValue}
        showButtons
      />
      <StorageContainer>
        <b>
          {__("family_manage_quota_modal_storage", "Storage")}: {" "}
          {formattedStorage} <SeparatingLine>/</SeparatingLine>
        </b>
        <SharedLinkText>
          {__("family_manage_quota_modal_traffic", "Download link traffic")}: {" "}
          {formattedStorage}
        </SharedLinkText>
      </StorageContainer>
    </Container>
  );
};

export default StorageManagement;

const Container = styled.div`
  margin-bottom: var(--spacing-xl);
  width: 100%;
`;

const StorageContainer = styled.div`
  width: 100%;
  display: flex;
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  justify-content: center;
  margin-top: var(--spacing-xs);

  @media (max-width: 520px) {
    flex-direction: column;
  };
`;

const StyledSlider = styled(Slider)`
  padding: 0;
`;

const SharedLinkText = styled.span`
  margin-left: 4px;
`

const SeparatingLine = styled.span`
  @media (max-width: 520px) {
    display: none;
  };
`