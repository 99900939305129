//@ flow
import React, { PureComponent, useEffect, useState } from "react";
import styled from "styled-components";
import RangeSlider from "./RangeSlider";

type Props = {
  minValue: number,
  maxValue: number,
  step?: number,
  startValue: number,
  minStartValue: number,
  disabled?: boolean,
  getSliderValue: string => void,
  formatLabel?: number => string
};

const Slider = ({
  startValue = 0,
  getSliderValue = () => { },
  formatLabel = value => "",
  maxValue,
  minValue,
  step,
  disabled,
  minStartValue,
  className,
  showButtons,
}: Props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(startValue);
    getSliderValue(startValue);
  }, []);

  const handleChange = (value) => {
    let currentValue = 0;

    if (value >= minStartValue) {
      currentValue = value;
    } else {
      currentValue = minStartValue;
    }

    // Protect going above partial step below the max value.
    if (value > (maxValue - (maxValue % step))) {
      currentValue = (maxValue - (maxValue % step));
    }

    setValue(currentValue);
    getSliderValue(currentValue);
  }

  return (
    <Container disabled={disabled} className={className}>
      <RangeSlider
        minValue={minValue}
        maxValue={maxValue}
        value={value}
        step={step}
        disabled={disabled}
        showLabel={formatLabel ? true : false}
        formatLabel={formatLabel}
        onRangeValueChange={handleChange}
        showButtons={showButtons}
      />
    </Container>
  );
}

export default Slider;

const Container = styled.div`
  padding: 16px 20px;
`;
