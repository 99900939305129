import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect } from "react";
import { validateEmail } from "../lib/utils";
import userinfo from "../api/userinfo";
import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";
import SubmitButton from "../components/SubmitButton";
import InputReadOnly from "../components/InputReadOnly";
import InputPassword from "../components/InputPassword";
import InputText from "../components/InputText";
import * as Style from "./SharedInvitationComponents";
import styles from "../styles/forms.less";
import Componentify from "react-componentify";
import { boldConverter, linkConverterV2 } from "../lib/componentifyConverters";
import PcloudLogo from "@pcloud/web-utilities/dist/resources/images/svg/pcloudLogo.svg";
import WarnIcon from "../../root/img/svg/warning_invitation.svg";

const DeactivateAccount = ({ code }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  let xhr;

  const deactivateAccount = useCallback(
    (e) => {
      e.preventDefault();

      if (password.length < 5) {
        setError(__("provide_password", "Provide password."));
        return;
      }

      setLoading(true);

      xhr = apiMethod(
        "deactivateuser",
        { code, password },
        (ret) => {
          setDone(true);
          setLoading(false);
        },
        {
          errorCallback: (ret) => {
            setError(ret.error);
            setPassword("");
            setLoading(false);
          },
        }
      );
    },
    [code, password]
  );

  useEffect(() => {
    return () => {
      if (xhr) {
        xhr.abort();
      }
    };
  }, [xhr]);

  const renderForm = () => (
    <Style.Form method="post" onSubmit={deactivateAccount}>
      {error && <div className="error">{error}</div>}
      <InputPassword
        name="password"
        placeholder={__("your_password", "Your password")}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        revealPassword={true}
      />
      <SubmitButton loading={loading} active={!loading}>
        {__("delete_account", "Delete Account")}
      </SubmitButton>
    </Style.Form>
  );

  const renderLoading = () => (
    <Style.LoadingWrapper>
      <div />
    </Style.LoadingWrapper>
  );

  const renderDone = () => (
    <Style.Message>
      <Style.ComponentifyWrapper>
        <Componentify text={__("your_account_deleted")} converters={[boldConverter]} />
      </Style.ComponentifyWrapper>
    </Style.Message>
  );

  if (loading) return renderLoading();

  if (!code) {
    return (
      <Style.Container>
        <Style.HeaderLogoContainer>
          <PcloudLogo />
        </Style.HeaderLogoContainer>
        <Style.Title>{__("Confirm account deletion")}</Style.Title>
        <Style.WarningMessage>
          <WarnIcon />
          <Style.ComponentifyWrapper>
            <Componentify text={__("invalid_data_arrived")} converters={[boldConverter, linkConverterV2]} />
          </Style.ComponentifyWrapper>
        </Style.WarningMessage>
      </Style.Container>
    );
  }

  return (
    <Style.Container>
      <Style.HeaderLogoContainer>
        <PcloudLogo />
      </Style.HeaderLogoContainer>
      {!done && (
        <>
          <Style.Title>{__("delete_confirm_delete")}</Style.Title>
          <Style.FormLabel>{__("delete_confirm_enter_password")}</Style.FormLabel>
        </>
      )}
      {done ? renderDone() : renderForm()}
    </Style.Container>
  );
};

DeactivateAccount.propTypes = {
  code: PropTypes.string.isRequired,
};

export default DeactivateAccount;
