import styled from "styled-components";
import { hiDPI } from "polished";

import { Loader } from "../TwoFactorAuth/SharedComponents";


export const Container = styled.div`
  border-radius: 21px;
  background-color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: var(--spaces-6) var(--spacing-lg) var(--spacing-lg) var(--spacing-lg);
  box-sizing: border-box;
  max-width: 560px;
  width: calc(100vw - 20px);
  
  @media (max-width: 600px) {
    width: 95vw;
  }

  a {
    min-width: 120px;
    width: auto;
    max-width: 200px;
  }

  .left {
    align-items: flex-start;
  }
`;

export const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;

  &.resend {
    margin-bottom: 30px;
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
`;

export const Label = styled.div`
  font-size: 13px;
  font-weight: normal;
  text-align: left;
  margin-bottom: 10px;
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 0;

  &.single {
    display: block;
  }

  a {
    display: flex;
    width: 100%;
    max-width: 100%;
    font-size: 15px;
    font-weight: normal;
    justify-content: center;
  }
  
  @media (max-width: 500px) {
    flex-direction: column;
    gap: 8px;
    & a {
      margin: 0!important;
    }
  }
`;

export const Title = styled.div`
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  margin: 15px 0 0 0;
  width: 100%;
`;

export const ActionIcon = styled.div`
  display: inline-block;
  width: 26px;
  height: 26px;
  cursor: pointer;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 12px;
  height: 12px;
  margin: 12px;
  cursor: pointer;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${require("../../../root/img/dlink/close.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../../root/img/dlink/close@2x.png")});
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const CloseButtonFamily = styled(CloseButton)`
  position: relative;
  margin: 0;
  top: 0px;
  left: 570px;
`;

export const LoaderFamily = styled(Loader)`
  position: relative;
  margin: 55px 0 0 -25px;
  top: 0;
  left: 50%;
`;
